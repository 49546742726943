// .app-blog .post-detail .post-detail-list article .post__categories a { color: #000; }
// .app-blog .blog__list .post__categories a {
//   // color: $dark-primary;
// }

.news{
  @include media-breakpoint-up(md) {
    article:nth-child(1) {
      .post__content{
        background-color: $primary;
        &, *{
          color: #000;
        }
      }
    }
  }
  article.post:not(:first-child){
    background-blend-mode: overlay;
    background-size: cover;
  }
}