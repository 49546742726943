$fa-font-path: "../fonts/fontawesome";
$slick-font-path: "../fonts/";
$slick-loader-path: "../img/";
$min-contrast-ratio: 3;
$primary: #FFC63D;
$dark-primary: darken($primary, 15%);
$secondary: #414142;

$theme-colors: (
  "primary":    $primary,
  "secondary":    $secondary,
);

$btn-border-radius: 2rem;
$btn-padding-y:  .75rem;
$btn-padding-x:     2rem ;