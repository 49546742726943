.btn-primary {
  color: #000;
}

.link-primary {
  color: $dark-primary !important;
}

a:not([class]),
.bg-secondary a:not([class]),
.breadcrumb .breadcrumb-item a {
  color: $dark-primary;
}