@charset "UTF-8";
// Core variables and mixins
@import "base/custom";
// scss-docs-start import-stack
// Configuration
@import "../../../libs/website_common/static/sass/base/bootstrap";
@import "../../../libs/website_common/static/sass/base/addons";

// base
@import "../../../libs/website_common/static/sass/base/typos";
@import "../../../libs/website_common/static/sass/base/common";

//layout
@import "../../../libs/website_common/static/sass/layout/header";
@import "../../../libs/website_common/static/sass/layout/main";
@import "../libs/website_common/static/sass/layout/footer";

:root {
  --bs-link-color: #{$dark-primary};
}

@import "layout/header";
@import "layout/footer";
@import "layout/main";
@import "base/common";

// modules
@import "../../../libs/website_common/static/sass/modules/burger_menu";
@import "../../../libs/website_common/static/sass/modules/cookieoptin";
@import "../../../libs/website_common/static/sass/modules/menu";
@import "../../../libs/website_common/static/sass/modules/figure";
@import "../../../libs/website_common/static/sass/modules/modules";
@import "../../../libs/website_common/static/sass/modules/image";
@import "../../../libs/website_common/static/sass/modules/brochure";
@import "../../../libs/website_common/static/sass/modules/skiplinks";